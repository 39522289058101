<template>
  <div class="deposits">
    <page-subnav class="deposits__subnav">
      <deposits-list-params
        class="deposits__subnav-action"
        :value="{
          sort: $route.query.sort || '',
          broker: $route.query.broker || '',
          depositState: $route.query.depositState || '',
        }"
        @input="onListParamsChange"
      />

      <template slot="right">
        <router-link
          class="deposits__tab"
          active-class="deposits__tab_active"
          :to="{
            params: { tab: DEPOSITS_URL_PARAM_TABS.holding },
            query: omit($route.query, ['depositState']),
          }"
        >
          {{ $t('HOLDING_TAB') }}
        </router-link>

        <router-link
          class="deposits__tab"
          active-class="deposits__tab_active"
          :to="{
            params: { tab: DEPOSITS_URL_PARAM_TABS.history },
            query: omit($route.query, ['accentLead']),
          }"
        >
          {{ $t('HISTORY_TAB') }}
        </router-link>
      </template>
    </page-subnav>

    <deposits-list
      class="deposits__list"
      :group-by="groupingAlgorithm"
      :template="template"
      @update-list-ask="loadDeposits"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import DepositsList from './components/DepositsList'
import DepositsListParams from './components/DepositsListParams'
import { depositsActions, depositsMutations } from './store/types'
import { mapActions, mapMutations } from 'vuex'
import { showError } from 'Utils/notifications'
import {
  DEPOSITS_URL_PARAM_TABS,
  DEPOSITS_TAB_DEFAULT_SORT,
  DEPOSITS_LEAD_TABS,
  DEPOSITS_QUERY_SORTS,
  DEPOSITS_TO_LEADS_SORT,
  LEAD_TO_DEPOSITS_SORT,
  DEPOSITS_QUERY_SORTS_BY_TABS,
} from 'Constants/depositsUrlParams'
import { DEPOSITS_TAB_TO_TEMPLATE_MAP } from './constants'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

export default {
  name: 'deposits',

  components: {
    PageSubnav,
    DepositsList,
    DepositsListParams,
  },

  data () {
    return {
      DEPOSITS_URL_PARAM_TABS,
      template: DEPOSITS_TAB_TO_TEMPLATE_MAP[DEPOSITS_URL_PARAM_TABS.holding],
      groupingAlgorithm: '',
    }
  },

  computed: {
    tabDefaultSort () {
      return DEPOSITS_TAB_DEFAULT_SORT[this.$route.params.tab]
    },

    metaTitle () {
      const tabTitles = {
        [DEPOSITS_URL_PARAM_TABS.holding]: this.$t('META_TITLE_HOLDING'),
        [DEPOSITS_URL_PARAM_TABS.history]: this.$t('META_TITLE_HISTORY'),
      }
      return tabTitles[this.$route.params.tab]
    },
  },

  watch: {
    '$route.params.tab': {
      immediate: true,
      handler (tab, oldTab) {
        if (isEqual(tab, oldTab)) return
        this.onListParamsChange(this.$route.query)
      }
    },
  },

  methods: {
    ...mapActions('ui/deposits', {
      loadHistory: depositsActions.LOAD_HISTORY,
      loadActiveDeposits: depositsActions.LOAD_ACTIVE_DEPOSITS,
    }),
    ...mapMutations('ui/deposits', {
      setIsLoading: depositsMutations.SET_IS_LOADING,
    }),
    omit,

    onListParamsChange (input = {}) {
      const query = { ...this.$route.query }
      const tab = this.$route.params.tab

      if (!input.sort || input.sort === this.tabDefaultSort) {
        query.sort = undefined
      } else if (DEPOSITS_QUERY_SORTS_BY_TABS[tab].includes(tab)) {
        query.sort = input.sort
      } else {
        const isLeadTab = DEPOSITS_LEAD_TABS.includes(tab)

        query.sort = isLeadTab
          ? DEPOSITS_TO_LEADS_SORT[input.sort]
          : LEAD_TO_DEPOSITS_SORT[input.sort]
      }

      query.broker = input.broker || undefined
      query.depositState = input.depositState || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }
      this.loadDeposits()
    },

    async loadDeposits () {
      const query = this.$route.query
      const payload = { filter: {} }

      payload.filter.broker = query.broker
      payload.filter.state = query.depositState

      const sort = query.sort || this.tabDefaultSort
      payload.sort = sort

      this.setIsLoading(true)

      try {
        const tabToAction = {
          [DEPOSITS_URL_PARAM_TABS.history]: this.loadHistory,
          [DEPOSITS_URL_PARAM_TABS.holding]: this.loadActiveDeposits,
        }
        await tabToAction[this.$route.params.tab](payload)
        this.postLoad()
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },

    postLoad () {
      this.template = DEPOSITS_TAB_TO_TEMPLATE_MAP[this.$route.params.tab]

      const sort = this.$route.query.sort || this.tabDefaultSort
      switch (sort) {
        case DEPOSITS_QUERY_SORTS.updatedAt:
        case DEPOSITS_QUERY_SORTS.updatedAtDesc:
          this.groupingAlgorithm = 'updatedAt'
          break
        case DEPOSITS_QUERY_SORTS.depositCreatedAt:
        case DEPOSITS_QUERY_SORTS.depositCreatedAtDesc:
          this.groupingAlgorithm = 'createdAt'
          break
        default:
          this.groupingAlgorithm = ''
      }
    },
  },

  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.deposits {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "HOLDING_TAB": "HOLDING",
    "HISTORY_TAB": "HISTORY",
    "META_TITLE_HOLDING": "Holding",
    "META_TITLE_HISTORY": "History",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the deposits list, please try again later or contact the system owner."
  },
  "ka": {
    "HOLDING_TAB": "ᲓᲐᲙᲐᲕᲔᲑᲣᲚᲘ",
    "HISTORY_TAB": "ᲘᲡᲢᲝᲠᲘᲐ",
    "META_TITLE_HOLDING": "დაკავებული",
    "META_TITLE_HISTORY": "ისტორია",
    "LIST_FETCH_FAILED_NOTIFY": "დეპოზიტების სია ვერ ჩამოიტვირთა, კიდევ სცადეთ, ან დაუკავშირდით ადმინისტრატორს."
  },
  "ru": {
    "HOLDING_TAB": "УДЕРЖИВАЮТСЯ",
    "HISTORY_TAB": "ИСТОРИЯ",
    "META_TITLE_HOLDING": "Удерживаются",
    "META_TITLE_HISTORY": "История",
    "LIST_FETCH_FAILED_NOTIFY": "Не удается загрузить список депозитов. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "HOLDING_TAB": "УТРИМУЮТЬСЯ",
    "HISTORY_TAB": "ІСТОРІЯ",
    "META_TITLE_HOLDING": "Утримуються",
    "META_TITLE_HISTORY": "Історія",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдається завантажити список депозитів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
