<template>
  <div class="deposit-cell-status deposits-cmn__cell">
    <template v-if="canApprove">
      <ui-button
        class="
          deposit-cell-status__iconed-btn
          deposit-cell-status__iconed-btn_error
        "
        @click="confirmApproveDeposit"
      >
        <ui-icon
          class="deposit-cell-status__iconed-btn-ico"
          icon="plus"
        />
      </ui-button>
    </template>

    <template v-if="canCharge">
      <ui-button
        class="
          deposit-cell-status__iconed-btn
          deposit-cell-status__iconed-btn_success
        "
        @click="isChargeModalShown = true"
      >
        <ui-icon
          class="deposit-cell-status__iconed-btn-ico"
          icon="plus"
        />
      </ui-button>
    </template>

    <deposit-payments-hint
      class="deposits-cmn__str deposit-cell-status__str"
      :class="{ 'deposit-cell-status__str_err': canApprove }"
      :deposit-id="deposit.id"
      :exclude-timeouts="false"
      :exclude-pending="false"
    >
      {{ statusTxt }}
    </deposit-payments-hint>

    <ui-spinner
      class="deposit-cell-status__spinner"
      v-if="isActionInProgress"
      type="pills"
      :overlay="true"
    />

    <deposit-modal-charge
      v-if="isChargeModalShown"
      class="deposit-cell-status__charge-modal"
      :deposit="deposit"
      @close="isChargeModalShown = false"
    />
  </div>
</template>

<script>
import { UiButton, UiIcon, UiSpinner } from '@shelf.network/ui-kit'
import DepositModalCharge from './DepositModalCharge'
import { Deposit } from 'Models/Deposit'
import { Customer } from 'Models/Customer'
import { confirmAction } from 'Utils/confirmAction'
import { DepositPaymentsHint } from 'Common/DepositPaymentsHint'
import { showSuccess, showError } from 'Utils/notifications'
import { mapActions } from 'vuex'
import { depositsActions } from '../store/types'
import { can } from 'Utils/userHelpers'
import { USER_CLAIMS } from 'Constants/userClaims'
import { trackUserEvent } from 'Services/analytics'

export default {
  name: 'deposit-cell-status',

  components: {
    UiButton,
    UiIcon,
    UiSpinner,
    DepositPaymentsHint,
    DepositModalCharge,
  },

  props: {
    deposit: {
      type: Deposit,
      required: true,
    },
    customer: {
      type: Customer,
      required: true
    }
  },

  data () {
    return {
      isActionInProgress: false,
      isChargeModalShown: false,
    }
  },

  computed: {
    canApprove () {
      return can(USER_CLAIMS.DEPOSITS_MANAGEMENT) && (
        this.deposit.state === Deposit.statesEnum.failed ||
        this.deposit.state === Deposit.statesEnum.pending
      )
    },

    canCharge () {
      return can(USER_CLAIMS.DEPOSITS_MANAGEMENT) && (
        this.deposit.state === Deposit.statesEnum.paid ||
        this.deposit.state === Deposit.statesEnum.locked
      )
    },

    statusTxt () {
      let res
      switch (this.deposit.state) {
        case Deposit.statesEnum.pending:
          res = this.$t('PENDING_STATUS_MSG')
          break
        case Deposit.statesEnum.paid:
          res = this.$t('PAID_STATUS_MSG')
          break
        case Deposit.statesEnum.returned:
          res = this.$t('RETURNED_STATUS_MSG')
          break
        case Deposit.statesEnum.returning:
          res = this.$t('RETURNING_STATUS_MSG')
          break
        case Deposit.statesEnum.feeCharged:
          res = this.$t('FEE_CHARGED_STATUS_MSG')
          break
        case Deposit.statesEnum.failed:
          res = this.$t('FAILED_STATUS_MSG')
          break
        case Deposit.statesEnum.reversed:
          res = this.$t('REVERSED_STATUS_MSG')
          break
        case Deposit.statesEnum.locked:
          res = this.$t('LOCKED_STATUS_MSG')
          break
        case Deposit.statesEnum.requestedWithdrawal:
          res = this.$t('REQUESTED_WITHDRAWAL_STATUS_MSG')
          break
      }
      return res
    },
  },

  methods: {
    ...mapActions('ui/deposits', {
      setDepositsStates: depositsActions.SET_DEPOSITS_STATES,
    }),

    async confirmApproveDeposit () {
      if (this.isActionInProgress) return

      if (!await confirmAction(this.$t('APPROVE_DEPOSIT_CONFIRM_MSG'))) {
        return
      }

      this.approveDeposit()
    },

    async approveDeposit () {
      this.isActionInProgress = true

      const successTxt = this.$t('APPROVE_DEPOSIT_SUCCESS_MSG')
      const failedTxt = this.$t('APPROVE_DEPOSIT_FAILED_MSG')
      try {
        await this.setDepositsStates({
          depositIds: [this.deposit.id],
          state: Deposit.statesEnum.paid,
        })
        showSuccess(successTxt)

        trackUserEvent(this.customer.contactPhone, 'Topped up buying power balance')
      } catch (error) {
        showError(failedTxt)
      }

      this.isActionInProgress = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/deposits.scss";

$color-error: #d67a7a;

.deposit-cell-status {
  position: relative;
  justify-self: start;

  &__iconed-btn {
    /deep/ .ui-button__button[fill][look] {
      color: $color-white;
      line-height: 1;
      width: 1.45em;
      height: 1.45em;
      padding: 0.1em 0 0 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_error {
      /deep/ .ui-button__button[fill][look] {
        background: $color-error;
        border-color: $color-error;
      }
    }

    &_success {
      /deep/ .ui-button__button[fill][look] {
        background: $color-sys-success;
        border-color: $color-sys-success;
      }
    }

    &-ico {
      font-weight: bold;
      font-size: 0.85em;
      line-height: 1;
    }
  }

  &__str {
    &_err {
      color: $color-error;
    }
  }

  &__spinner.ui-spinner {
    background: rgba($color-ui-bg, 0.9);
  }
}
</style>

<i18n>
{
  "en": {
    "FAILED_STATUS_MSG": "Failed",
    "RETURNED_STATUS_MSG": "Returned",
    "LOCKED_STATUS_MSG": "Holding(Attached)",
    "PAID_STATUS_MSG": "Holding",
    "PENDING_STATUS_MSG": "Pending",
    "RETURNING_STATUS_MSG": "Returning",
    "FEE_CHARGED_STATUS_MSG": "Fee charged",
    "REVERSED_STATUS_MSG": "Reversed",
    "REQUESTED_WITHDRAWAL_STATUS_MSG": "Requested withdrawal",
    "APPROVE_DEPOSIT_CONFIRM_MSG": "Set the deposit to the paid state? The action is irreversible.",
    "APPROVE_DEPOSIT_SUCCESS_MSG": "The deposit set to PAID",
    "APPROVE_DEPOSIT_FAILED_MSG": "Failed to update the deposit. Please try again later or contact the system owner."
  },
  "ka": {
    "FAILED_STATUS_MSG": "შეცდომა",
    "RETURNED_STATUS_MSG": "დაფრუნებული",
    "LOCKED_STATUS_MSG": "Holding(Attached)",
    "PAID_STATUS_MSG": "Holding",
    "PENDING_STATUS_MSG": "მუშავდება",
    "RETURNING_STATUS_MSG": "მუშავდება",
    "FEE_CHARGED_STATUS_MSG": "ჩამოჭრილი",
    "REVERSED_STATUS_MSG": "სრულად დაბრუნებული",
    "REQUESTED_WITHDRAWAL_STATUS_MSG": "გატანის მოთხოვნა",
    "APPROVE_DEPOSIT_CONFIRM_MSG": "გსურთ ხელით დეპოზიტის დადასტურება? ქმედებას ვეღარ შეცვლით.",
    "APPROVE_DEPOSIT_SUCCESS_MSG": "დეპოზიტი შეიცვალა გადახდილად",
    "APPROVE_DEPOSIT_FAILED_MSG": "დეპოზიტის დადასტურება ვერ მოხერხდა. კიდევ სცადეთ, ან დაუკავშირდით ადმინისტრატორს."
  },
  "ru": {
    "FAILED_STATUS_MSG": "Ошибка",
    "RETURNED_STATUS_MSG": "Возвращен",
    "LOCKED_STATUS_MSG": "Прикреплен",
    "PAID_STATUS_MSG": "На удержании",
    "PENDING_STATUS_MSG": "Ожидается",
    "RETURNING_STATUS_MSG": "Возвращается",
    "FEE_CHARGED_STATUS_MSG": "Стянута комиссия",
    "REVERSED_STATUS_MSG": "Аннулирован",
    "REQUESTED_WITHDRAWAL_STATUS_MSG": "Запрошен вывод",
    "APPROVE_DEPOSIT_CONFIRM_MSG": "Отметить депозит как оплаченный? Действие необратимо.",
    "APPROVE_DEPOSIT_SUCCESS_MSG": "Депозит помечен как ОПЛАЧЕННЫЙ",
    "APPROVE_DEPOSIT_FAILED_MSG": "Не удалось обновить депозит. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "FAILED_STATUS_MSG": "Помилка",
    "RETURNED_STATUS_MSG": "Повернений",
    "LOCKED_STATUS_MSG": "Прикріплено",
    "PAID_STATUS_MSG": "На утриманні",
    "PENDING_STATUS_MSG": "Очікується",
    "RETURNING_STATUS_MSG": "Повертається",
    "FEE_CHARGED_STATUS_MSG": "Комісію стягнуто",
    "REVERSED_STATUS_MSG": "Анульовано",
    "REQUESTED_WITHDRAWAL_STATUS_MSG": "Запрошено виведення",
    "APPROVE_DEPOSIT_CONFIRM_MSG": "Позначити депозит як сплачений? Дія незворотна.",
    "APPROVE_DEPOSIT_SUCCESS_MSG": "Депозит позначений як СПЛАЧЕНИЙ",
    "APPROVE_DEPOSIT_FAILED_MSG": "Не вдалось оновити депозит. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
