<template>
  <div
    class="deposit-item"
    :class="{ 'deposit-item_accented': isAccented }"
  >
    <div class="deposits-cmn__row">
      <cell-customer
        class="deposit-item__cell"
        :customer="item.customer"
      />

      <cell-broker
        class="deposit-item__cell"
        :broker="item.broker"
      />

      <deposit-cell-date
        class="deposit-item__cell"
        :deposit="item.deposits[0]"
      />

      <deposit-cell-amounts
        class="deposit-item__cell"
        :deposits="item.deposits"
        :is-payments-status-hint-shown="
          template === DEPOSITS_LIST_TEMPLATES.holding
        "
      />

      <template v-if="template === DEPOSITS_LIST_TEMPLATES.history">
        <deposit-cell-status
          class="deposit-item__cell"
          :deposit="item.deposits[0]"
          :customer="item.customer"
        />
      </template>

      <template v-else>
        <deposit-cell-return
          class="deposit-item__cell"
          :deposits="item.deposits"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { DEPOSITS_LIST_TEMPLATES } from '../constants'
import { DepositEntry } from '../models/DepositEntry'
import { CellCustomer, CellBroker } from 'Common/cells'
import DepositCellDate from './DepositCellDate'
import DepositCellAmounts from './DepositCellAmounts'
import DepositCellStatus from './DepositCellStatus'
import DepositCellReturn from './DepositCellReturn'

export default {
  name: 'deposit-item',

  components: {
    CellCustomer,
    CellBroker,
    DepositCellDate,
    DepositCellAmounts,
    DepositCellStatus,
    DepositCellReturn,
  },

  props: {
    template: {
      type: String,
      default: DEPOSITS_LIST_TEMPLATES.playing,
      validator (value) {
        return Object.values(DEPOSITS_LIST_TEMPLATES).includes(value)
      },
    },

    item: {
      type: DepositEntry,
      required: true,
    },
  },

  data () {
    return {
      DEPOSITS_LIST_TEMPLATES,
    }
  },

  computed: {
    isAccented () {
      return this.$route.query.accentLead === this.item.leadId
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/deposits";

.deposit-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);

  &_accented {
    border-color: $color-sys-pending;
    border-width: 3px;
  }
}
</style>
