import { ModelBase } from 'Models/ModelBase'
import { Deposit } from 'Models/Deposit'
import { Customer } from 'Models/Customer'
import { Identity } from 'Models/Identity'

export class DepositEntry extends ModelBase {
  constructor (raw = {}) {
    super(raw)

    this.leadId = this._str(raw.leadId)
    this.customer = new Customer(raw.customer)
    this.broker = new Identity(raw.broker)
    this.deposits = this._arrOf(raw.deposits, Deposit)
  }
}
