<template>
  <list-params
    class="deposits-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <list-params-sort
        class="deposits-list-params__row"
        v-model="form.sort"
        :options="sortOptions"
        :default-value="defaultSort"
      />

      <list-params-broker
        class="deposits-list-params__row"
        v-model="form.broker"
      />

      <template v-if="!isLeadTab">
        <list-params-deposit-state
          class="deposits-list-params__row"
          v-model="form.depositState"
        />
      </template>
    </template>
  </list-params>
</template>

<script>
import {
  DEPOSITS_QUERY_SORTS,
  DEPOSITS_TAB_DEFAULT_SORT,
  DEPOSITS_LEAD_TABS,
  DEPOSITS_QUERY_SORTS_BY_TABS,
} from 'Constants/depositsUrlParams'
import {
  ListParams,
  ListParamsSort,
  ListParamsBroker,
  ListParamsDepositState,
} from 'Common/ListParams'

export default {
  name: 'deposits-list-params',
  components: {
    ListParams,
    ListParamsSort,
    ListParamsBroker,
    ListParamsDepositState,
  },

  props: {
    value: {
      type: Object,
      required: true,
      validator (value) {
        return typeof value.sort === 'string' &&
          typeof value.broker === 'string' &&
          typeof value.depositState === 'string'
      },
    },
  },

  data () {
    return {
      form: {
        sort: '',
        broker: '',
        depositState: '',
      },
    }
  },

  computed: {
    sortOptions () {
      const allSorts = [
        {
          value: DEPOSITS_QUERY_SORTS.updatedAtDesc,
          label: this.$t('SORT_NEW_FIRST_OPT'),
        },
        {
          value: DEPOSITS_QUERY_SORTS.updatedAt,
          label: this.$t('SORT_NEW_LAST_OPT'),
        },
        {
          value: DEPOSITS_QUERY_SORTS.depositCreatedAtDesc,
          label: this.$t('SORT_NEW_FIRST_OPT'),
        },
        {
          value: DEPOSITS_QUERY_SORTS.depositCreatedAt,
          label: this.$t('SORT_NEW_LAST_OPT'),
        },
      ]

      return allSorts.filter(s => {
        const tab = this.$route.params.tab
        const value = s.value

        return DEPOSITS_QUERY_SORTS_BY_TABS[tab].includes(value)
      })
    },

    defaultSort () {
      return DEPOSITS_TAB_DEFAULT_SORT[this.$route.params.tab]
    },

    isLeadTab () {
      return DEPOSITS_LEAD_TABS.includes(this.$route.params.tab)
    },
  },
}
</script>

<i18n>
{
  "en": {
    "SORT_NEW_FIRST_OPT": "New first",
    "SORT_NEW_LAST_OPT": "New last"
  },
  "ka": {
    "SORT_NEW_FIRST_OPT": "ბოლო მომართვები",
    "SORT_NEW_LAST_OPT": "ადრინდელი მომართვები"
  },
  "ru": {
    "SORT_NEW_FIRST_OPT": "Сначала новые",
    "SORT_NEW_LAST_OPT": "Сначала старые"
  },
  "uk": {
    "SORT_NEW_FIRST_OPT": "Спочатку нові",
    "SORT_NEW_LAST_OPT": "Спочатку старі"
  }
}
</i18n>
