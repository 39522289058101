<template>
  <div class="deposit-cell-return deposits-cmn__cell">
    <div class="deposit-cell-return__btn-wrp">
      <ui-button
        class="deposit-cell-return__btn"
        :class="{
          'deposit-cell-return__btn_withdraw': hasDepositToWithdraw,
        }"
        @click="onReturnClick"
        :is-disabled="!isReturnPermitted || !hasDepositToReturn"
        :title="buttonHint"
      >
        <template v-if="!hasDepositToWithdraw">
          {{ $t('RETURN_BTN') }}
        </template>

        <template v-else>
          {{ $t('WITHDRAW_BTN') }}
        </template>
      </ui-button>

      <ui-spinner
        class="deposit-cell-return__spinner"
        v-if="isActionInProgress"
        type="pills"
        :overlay="true"
      />
    </div>

    <deposit-modal-return
      class="deposit-cell-return__return-modal"
      :is-shown="isReturnModalShown"
      :deposits="deposits"
      @submit="returnDeposits($event)"
      @close="hideReturnModal"
    />
  </div>
</template>

<script>
import { UiButton, UiSpinner } from '@shelf.network/ui-kit'
import { validateArrayOf } from 'Models/modelUtils'
import { Deposit } from 'Models/Deposit'
import DepositModalReturn from './DepositModalReturn'
import { confirmAction } from 'Utils/confirmAction'
import { mapActions } from 'vuex'
import { depositsActions } from '../store/types'
import { showSuccess, showError } from 'Utils/notifications'
import { can } from 'Utils/userHelpers'
import { USER_CLAIMS } from 'Constants/userClaims'

export default {
  name: 'deposit-cell-return',

  components: {
    UiButton,
    UiSpinner,
    DepositModalReturn,
  },

  props: {
    deposits: {
      type: Array,
      required: true,
      validator: validateArrayOf(Deposit),
    },
  },

  data () {
    return {
      isActionInProgress: false,
      isReturnModalShown: false,
    }
  },

  computed: {
    isReturnPermitted () {
      return can(USER_CLAIMS.DEPOSITS_MANAGEMENT)
    },

    hasDepositToReturn () {
      return this.deposits.some(el => el.isReturnable)
    },

    hasDepositToWithdraw () {
      return this.deposits
        .some(el => el.state === Deposit.statesEnum.requestedWithdrawal)
    },

    buttonHint () {
      if (!this.isReturnPermitted) return this.$t('RETURN_NO_PERMIT_HINT')

      if (this.hasDepositToReturn) return

      const areAllReturned = this.deposits.every(el => el.isReturned)
      if (areAllReturned) {
        return this.$t('ALREADY_RETURNED_HINT')
      }

      return this.$t('NOTHING_TO_RETURN_HINT')
    },
  },

  methods: {
    ...mapActions('ui/deposits', {
      setDepositsStates: depositsActions.SET_DEPOSITS_STATES,
    }),

    showReturnModal () {
      this.isReturnModalShown = true
    },

    hideReturnModal () {
      this.isReturnModalShown = false
    },

    onReturnClick () {
      if (!this.hasDepositToReturn || this.isActionInProgress) return

      if (this.deposits.length === 1) {
        this.returnSingleDeposit()
        return
      }

      this.showReturnModal()
    },

    async returnSingleDeposit () {
      const deposit = this.deposits[0]
      const amount = this.$fc(deposit.amount, deposit.currency)
      const confirmMsg = this.$t('SINGLE_RETURN_CONFIRM_MSG', { amount })
      if (!await confirmAction(confirmMsg)) return

      this.returnDeposits([deposit.id])
    },

    async returnDeposits (ids) {
      this.isActionInProgress = true

      const successTxt = this.$tc('RETURN_SUCCESS_MSG', ids.length)
      const failedTxt = this.$t('RETURN_FAILED_MSG')
      try {
        await this.setDepositsStates({
          depositIds: ids,
          state: Deposit.statesEnum.returning,
        })
        showSuccess(successTxt)
      } catch (error) {
        showError(failedTxt)
      }

      this.isActionInProgress = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/deposits.scss";

.deposit-cell-return {
  &__btn-wrp {
    position: relative;
  }

  &__btn {
    &_withdraw {
      /deep/ .ui-button__button {
        border-color: transparent;
        background: $color-flag-is-pending;

        &:hover {
          border-color: transparent;
          background: mix($color-flag-is-pending, $color-ui-default, 80);
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "RETURN_BTN": "Return",
    "WITHDRAW_BTN": "Withdraw",
    "NOTHING_TO_RETURN_HINT": "No deposits to return",
    "ALREADY_RETURNED_HINT": "All deposits are returned or being returned",
    "RETURN_NO_PERMIT_HINT": "Deposit return is not allowed",
    "SINGLE_RETURN_CONFIRM_MSG": "Return {amount} to the depositor? The action is irreversible.",
    "RETURN_SUCCESS_MSG": "- | The deposit has been returned | The deposits have been returned",
    "RETURN_FAILED_MSG": "Failed to return the deposits. Please try again later or contact the system owner."
  },
  "ka": {
    "RETURN_BTN": "დაბრუნება",
    "WITHDRAW_BTN": "დაბრუნება",
    "NOTHING_TO_RETURN_HINT": "დასაბრუნებელი დეპოზიტი არ არის",
    "ALREADY_RETURNED_HINT": "ყველა დეპოზიტი დაბრუნებულია",
    "RETURN_NO_PERMIT_HINT": "დეპოზიტის დაბრუნება შეუძლებელია",
    "SINGLE_RETURN_CONFIRM_MSG": "დაუბრუნდეს {amount} კლიენტს? დადასტურების შემდეგ გაუქმება შეუძლებელია.",
    "RETURN_SUCCESS_MSG": "- | დეპოზიტი დაბრუნებულია | დეპოზიტები დაბრუნებულია",
    "RETURN_FAILED_MSG": "დეპოზიტის დაბრუნება ვერ მოხერხდა. კიდევ სცადეთ, ან დაუკავშირდით ადმინისტრატორს."
  },
  "ru": {
    "RETURN_BTN": "Вернуть",
    "WITHDRAW_BTN": "Вывести",
    "NOTHING_TO_RETURN_HINT": "Нет депозитов для возврата",
    "ALREADY_RETURNED_HINT": "Все депозиты возвращены или возвращаются",
    "RETURN_NO_PERMIT_HINT": "Возврат депозитов не разрешен",
    "SINGLE_RETURN_CONFIRM_MSG": "Вернуть {amount} автору депозита? Действие необратимо.",
    "RETURN_SUCCESS_MSG": "- | Депозит возвращен | Депозиты возвращены",
    "RETURN_FAILED_MSG": "Не удалось вернуть депозит. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "RETURN_BTN": "Повернути",
    "WITHDRAW_BTN": "Вивести",
    "NOTHING_TO_RETURN_HINT": "Немає депозитів для повернення",
    "ALREADY_RETURNED_HINT": "Всі депозити повернено або повертаються",
    "RETURN_NO_PERMIT_HINT": "Повертання депозитів не дозволено",
    "SINGLE_RETURN_CONFIRM_MSG": "Повернути {amount} автору депозиту? Дія незворотна.",
    "RETURN_SUCCESS_MSG": "- | Депозит повернено | Депозити повернено",
    "RETURN_FAILED_MSG": "Не вдалось повернути депозит. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
