<template>
  <div class="deposits-list-head deposits-cmn__row">
    <span class="deposits-list-head__cell">
      {{ $t('USER_HEAD_CELL') }}
    </span>

    <span class="deposits-list-head__cell">
      {{ $t('ASSIGNED_TO_HEAD_CELL') }}
    </span>

    <span class="deposits-list-head__cell">
      <template v-if="template === DEPOSITS_LIST_TEMPLATES.holding">
        {{ $t('FREEZE_DATE_HEAD_CELL') }}
      </template>

      <template v-else>
        {{ $t('DATE_HEAD_CELL') }}
      </template>
    </span>

    <span class="deposits-list-head__cell">
      {{ $t('DEPOSIT_HEAD_CELL') }}
    </span>

    <span class="deposits-list-head__cell">
      {{ $t('STATE_HEAD_CELL') }}
    </span>
  </div>
</template>

<script>
import { DEPOSITS_LIST_TEMPLATES } from '../constants'

export default {
  name: 'deposits-list-head',

  props: {
    template: {
      type: String,
      default: DEPOSITS_LIST_TEMPLATES.playing,
      validator (value) {
        return Object.values(DEPOSITS_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      DEPOSITS_LIST_TEMPLATES,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/deposits.scss";

.deposits-list-head {
  padding: 2em 3em 0;

  &__cell {
    @extend .deposits-cmn__cell;

    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "USER_HEAD_CELL": "USER",
    "ASSIGNED_TO_HEAD_CELL": "ASSIGNED TO",
    "FREEZE_DATE_HEAD_CELL": "DATE OF FREEZE",
    "DATE_HEAD_CELL": "DATE",
    "DEPOSIT_HEAD_CELL": "DEPOSIT",
    "STATE_HEAD_CELL": "STATE"
  },
  "ka": {
    "USER_HEAD_CELL": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲔᲚᲘ",
    "ASSIGNED_TO_HEAD_CELL": "ᲛᲘᲛᲐᲒᲠᲔᲑᲣᲚᲘ",
    "FREEZE_DATE_HEAD_CELL": "ᲩᲐᲠᲘᲪᲮᲕᲘᲡ ᲗᲐᲠᲘᲦᲘ",
    "DATE_HEAD_CELL": "ᲗᲐᲠᲘᲦᲘ",
    "DEPOSIT_HEAD_CELL": "ᲓᲔᲞᲝᲖᲘᲢᲘ",
    "STATE_HEAD_CELL": "ᲡᲢᲐᲢᲣᲡᲘ"
  },
  "ru": {
    "USER_HEAD_CELL": "ПОЛЬЗОВАТЕЛЬ",
    "ASSIGNED_TO_HEAD_CELL": "НАЗНАЧЕН",
    "FREEZE_DATE_HEAD_CELL": "ДАТА ЗАМОРОЗКИ",
    "DATE_HEAD_CELL": "ДАТА",
    "DEPOSIT_HEAD_CELL": "ДЕПОЗИТ",
    "STATE_HEAD_CELL": "СОСТОЯНИЕ"
  },
  "uk": {
    "USER_HEAD_CELL": "КОРИСТУВАЧ",
    "ASSIGNED_TO_HEAD_CELL": "ПРИЗНАЧЕНИЙ",
    "FREEZE_DATE_HEAD_CELL": "ДАТА ЗАМОРОЗКИ",
    "DATE_HEAD_CELL": "ДАТА",
    "DEPOSIT_HEAD_CELL": "ДЕПОЗИТ",
    "STATE_HEAD_CELL": "СТАН"
  }
}
</i18n>
