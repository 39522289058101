import { render, staticRenderFns } from "./DepositCellStatus.vue?vue&type=template&id=21fb4800&scoped=true&"
import script from "./DepositCellStatus.vue?vue&type=script&lang=js&"
export * from "./DepositCellStatus.vue?vue&type=script&lang=js&"
import style0 from "./DepositCellStatus.vue?vue&type=style&index=0&id=21fb4800&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21fb4800",
  null
  
)

/* custom blocks */
import block0 from "./DepositCellStatus.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports