<template>
  <list-grouped
    class="deposits-list"
    :list="deposits"
    :list-total-count="depositsCount"
    :group-by-date-field="groupByFieldName"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="DEPOSITS_PAGE_LIMIT"
    :load-more="loadMore"
    :reset-on-change="template"
    :head-component="DepositsListHead"
    :head-component-props="{ template }"
    :item-component="DepositItem"
    :item-component-props="{ template }"
    :item-skeleton-component="DepositItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import DepositItem from './DepositItem'
import DepositItemSkeleton from './DepositItemSkeleton'
import DepositsListHead from './DepositsListHead'
import ListGrouped from 'Common/ListGrouped'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { depositsMutations, depositsGetters, depositsActions } from '../store/types'
import { DEPOSITS_LIST_TEMPLATES, DEPOSITS_PAGE_LIMIT } from '../constants'
import { mutations as leadsMutations } from 'Components/Leads/store/types'

const GROUP_BY_ENUM = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
}

const GROUP_BY_TO_FIELD_MAP = {
  [GROUP_BY_ENUM.createdAt]: 'deposits[0].createdAt',
  [GROUP_BY_ENUM.updatedAt]: 'deposits[0].updatedAt',
}

export default {
  name: 'deposits-list',

  components: {
    ListGrouped,
  },

  props: {
    groupBy: {
      type: String,
      default: '',
      validator (value) {
        return !value || Object.values(GROUP_BY_ENUM).includes(value)
      },
    },

    template: {
      type: String,
      default: DEPOSITS_LIST_TEMPLATES.playing,
      validator (value) {
        return Object.values(DEPOSITS_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      DepositItem,
      DepositItemSkeleton,
      DepositsListHead,
      DEPOSITS_PAGE_LIMIT,
      storeUnsubscriber: () => { },
    }
  },

  computed: {
    ...mapGetters('ui/deposits', {
      deposits: depositsGetters.LIST,
      isLoading: depositsGetters.IS_LOADING,
      isNextLoading: depositsGetters.IS_NEXT_LOADING,
      depositsCount: depositsGetters.LIST_COUNT,
    }),

    groupByFieldName () {
      return GROUP_BY_TO_FIELD_MAP[this.groupBy]
    },
  },

  created () {
    this.storeUnsubscriber = this.$store.subscribe((mutation, state) => {
      if (mutation.type === `ui/leads/${leadsMutations.UPDATE_LEAD}`) {
        const { id: leadId, broker } = mutation.payload
        if (broker) {
          this.updateListItems({ leadId, broker })
        }
      }
    })
  },

  beforeDestroy () {
    this.storeUnsubscriber()
  },

  methods: {
    ...mapMutations('ui/deposits', {
      updateListItems: depositsMutations.UPDATE_LIST_ITEMS,
    }),
    ...mapActions('ui/deposits', {
      loadMore: depositsActions.LOAD_MORE,
    })
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/deposits.scss";

.deposits-list {
  --list-padding-side: 3em;
}
</style>
