<template>
  <ui-portal>
    <ui-modal
      class="deposit-modal-charge"
      :title="$t('CHARGE_TITLE')"
      :show-close-btn="!isSubmitting"
      mode-xs="drawerBottom"
      @close="close"
    >
      <form
        class="deposit-modal-charge__form"
        :class="{ 'deposit-modal-charge__form_loading': isSubmitting }"
        @submit.prevent="submit"
      >
        <div class="deposit-modal-charge__field">
          {{ $t('AMOUNT_LBL') }}
          <ui-text
            class="deposit-modal-charge__field-input"
            :placeholder="$t('AMOUNT_PH')"
            v-model="form.amount"
            :is-error="formErrors.amount.length > 0"
            @input="formErrors.amount = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.amount"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-text>
        </div>

        <ui-button
          type="submit"
          class="deposit-modal-charge__submit-btn"
        >
          {{ $t('CHARGE_BTN') }}
        </ui-button>
      </form>

      <ui-spinner
        class="deposit-modal-charge__loader"
        overlay
        type="pills"
        :show="isSubmitting"
      />
    </ui-modal>
  </ui-portal>
</template>

<script>
import {
  UiText,
  UiButton,
  UiModal,
  UiPortal,
  UiSpinner
} from '@shelf.network/ui-kit'
import { Deposit } from 'Models/Deposit'
import validationSchema from '../json-schemas/charge-validation.json'
import { jsv } from 'Utils/jsv'
import { jsvLocalizeError } from 'Utils/jsvLocalizeError'
import { showError, showSuccess } from 'Utils/notifications'
import { mapActions } from 'vuex'
import { depositsActions } from '../store/types'

export default {
  name: 'deposit-modal-charge',

  components: {
    UiText,
    UiButton,
    UiModal,
    UiPortal,
    UiSpinner,
  },

  props: {
    deposit: {
      type: Deposit,
      required: true,
    },
  },

  data () {
    return {
      isSubmitting: false,
      form: { amount: '' },
      formErrors: { amount: [] }
    }
  },

  watch: {
    deposit: {
      handler (val) {
        this.form.amount = val.amount
        this.formErrors.amount = []
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('ui/deposits', {
      chargeDeposit: depositsActions.CHARGE_DEPOSIT,
    }),

    validateForm () {
      if (Object.values(this.formErrors).some(el => el.length > 0)) return false

      const result = jsv.validate(validationSchema, this.form)
      if (result.isValid) return true

      Object.assign(this.formErrors, result.byField())
      return false
    },

    async submit () {
      if (!this.validateForm()) return

      try {
        this.isSubmitting = true
        await this.chargeDeposit({
          depositId: this.deposit.id,
          amount: this.form.amount,
        })
        showSuccess(this.$t('CHARGE_SUCCESS_MSG'))
        this.close()
      } catch (error) {
        showError(this.$t('CHARGE_ERROR_MSG'))
        console.error(error)
      } finally {
        this.isSubmitting = false
      }
    },

    close () {
      this.$emit('close')
    },

    jsvLocalizeError,
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/deposit-modal";

.deposit-modal-charge {
  &__form {
    min-width: 25em;
    max-width: 25em;

    @include respond-below(md) {
      max-width: 100%;
    }

    &_loading {
      filter: blur(0.2em);
    }
  }

  &__field {
    &-input {
      margin-top: 0.4em;
    }
  }

  &__submit-btn {
    margin-top: 2em;
    width: 100%;
  }
}
</style>

<i18n>
{
  "en": {
    "CHARGE_TITLE": "Charge deposit",
    "AMOUNT_LBL": "Amount",
    "AMOUNT_PH": "Enter amount",
    "CHARGE_BTN": "Charge",
    "CHARGE_SUCCESS_MSG": "Deposit charged",
    "CHARGE_ERROR_MSG": "Error while charging deposit"
  },
  "ka": {
    "CHARGE_TITLE": "დეპოზიტის ჩამოჭრა",
    "AMOUNT_LBL": "თანხა",
    "AMOUNT_PH": "შეიყვანე თანხა",
    "CHARGE_BTN": "ჩამოჭრა",
    "CHARGE_SUCCESS_MSG": "ჩამოჭრილია",
    "CHARGE_ERROR_MSG": "შეცდომა ჩამოჭრისას"
  },
  "ru": {
    "CHARGE_TITLE": "Взыскать депозит",
    "AMOUNT_LBL": "Сумма",
    "AMOUNT_PH": "Введите сумму",
    "CHARGE_BTN": "Взыскать",
    "CHARGE_SUCCESS_MSG": "Депозит взыскан",
    "CHARGE_ERROR_MSG": "Ошибка при взыскании депозита"
  },
  "uk": {
    "CHARGE_TITLE": "Стягнути депозит",
    "AMOUNT_LBL": "Сума",
    "AMOUNT_PH": "Введіть суму",
    "CHARGE_BTN": "Стягнути",
    "CHARGE_SUCCESS_MSG": "Депозит стягнуто",
    "CHARGE_ERROR_MSG": "Помилка при стягненні депозиту"
  }
}
</i18n>
