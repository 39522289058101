<template>
  <div class="deposit-cell-amounts deposits-cmn__cell">
    <template v-if="isPaymentsStatusHintShown">
      <deposit-payments-hint
        class="deposits-cmn__str deposit-cell-amounts__str"
        :class="{
          'deposit-cell-amounts__str_success': !hasDepositToWithdraw,
          'deposit-cell-amounts__str_pending': hasDepositToWithdraw,
        }"
        :deposit-id="depositIds"
        :exclude-timeouts="false"
        :exclude-pending="false"
      >
        {{ $fc(sum, currency) }}
      </deposit-payments-hint>
    </template>

    <template v-else>
      <span
        class="deposits-cmn__str deposits-cmn__str_sec"
        :title="`${sum} ${currency}`"
      >
        {{ $fc(sum, currency) }}
      </span>
    </template>

    <template v-if="count > 1">
      <span
        class="deposits-cmn__str deposits-cmn__str_sec"
        :title="$tc('COUNT_HINT', count, { count })"
      >
        {{ $t('COUNT_FORMAT', { count }) }}
      </span>
    </template>
  </div>
</template>

<script>
import { Deposit } from 'Models/Deposit'
import { validateArrayOf } from 'Models/modelUtils'
import { DepositPaymentsHint } from 'Common/DepositPaymentsHint'
export default {
  name: 'deposit-cell-amounts',

  components: {
    DepositPaymentsHint,
  },

  props: {
    deposits: {
      type: Array,
      required: true,
      validator: validateArrayOf(Deposit),
    },

    isPaymentsStatusHintShown: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    sum () {
      return this.deposits.reduce((acc, cur) => acc + Number(cur.amount), 0)
    },

    currency () {
      return this.deposits[0].currency
    },

    count () {
      return this.deposits.length
    },

    depositIds () {
      return this.deposits.map(el => el.id).join(',')
    },

    hasDepositToWithdraw () {
      return this.deposits
        .some(el => el.state === Deposit.statesEnum.requestedWithdrawal)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/deposits.scss";

.deposit-cell-amounts {
  &__str {
    &_success {
      color: $color-flag-is-success;
    }

    &_pending {
      color: $color-flag-is-pending;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "COUNT_FORMAT": "({count})",
    "COUNT_HINT": "- | - | {count} deposits"
  },
  "ka": {
    "COUNT_FORMAT": "({count})",
    "COUNT_HINT": "- | - | {count} დეპოზიტი"
  },
  "ru": {
    "COUNT_FORMAT": "({count})",
    "COUNT_HINT": "- | - | {count} депозита | {count} депозитов"
  },
  "uk": {
    "COUNT_FORMAT": "({count})",
    "COUNT_HINT": "- | - | {count} депозита | {count} депозитів"
  }
}
</i18n>
