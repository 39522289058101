import { DEPOSITS_URL_PARAM_TABS } from 'Constants/depositsUrlParams'

export const DEPOSITS_LIST_TEMPLATES = Object.freeze({
  holding: 'holding',
  history: 'history',
})

export const DEPOSITS_TAB_TO_TEMPLATE_MAP = Object.freeze({
  [DEPOSITS_URL_PARAM_TABS.holding]: DEPOSITS_LIST_TEMPLATES.holding,
  [DEPOSITS_URL_PARAM_TABS.history]: DEPOSITS_LIST_TEMPLATES.history,
})

export const DEPOSITS_PAGE_LIMIT = 15
