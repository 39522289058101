var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deposits"},[_c('page-subnav',{staticClass:"deposits__subnav"},[_c('deposits-list-params',{staticClass:"deposits__subnav-action",attrs:{"value":{
        sort: _vm.$route.query.sort || '',
        broker: _vm.$route.query.broker || '',
        depositState: _vm.$route.query.depositState || '',
      }},on:{"input":_vm.onListParamsChange}}),_vm._v(" "),_c('template',{slot:"right"},[_c('router-link',{staticClass:"deposits__tab",attrs:{"active-class":"deposits__tab_active","to":{
          params: { tab: _vm.DEPOSITS_URL_PARAM_TABS.holding },
          query: _vm.omit(_vm.$route.query, ['depositState']),
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('HOLDING_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"deposits__tab",attrs:{"active-class":"deposits__tab_active","to":{
          params: { tab: _vm.DEPOSITS_URL_PARAM_TABS.history },
          query: _vm.omit(_vm.$route.query, ['accentLead']),
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('HISTORY_TAB'))+"\n      ")])],1)],2),_vm._v(" "),_c('deposits-list',{staticClass:"deposits__list",attrs:{"group-by":_vm.groupingAlgorithm,"template":_vm.template},on:{"update-list-ask":_vm.loadDeposits}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }