<template>
  <ui-portal>
    <ui-modal
      class="deposit-modal-return"
      mode-xs="drawerBottom"
      :title="$t('CHOOSE_TITLE')"
      @close="close()"
      v-if="isShown"
    >
      <div class="deposit-modal-return__inner">
        <div class="deposit-modal-return__list">
          <div class="deposit-modal-return__list-head">
            <span class="deposit-modal-return__list-head-cell" />

            <span class="deposit-modal-return__list-head-cell">
              {{ $t('AMOUNT_HEAD_CELL') }}
            </span>

            <span class="deposit-modal-return__list-head-cell">
              {{ $t('CREATED_AT_HEAD_CELL') }}
            </span>
          </div>

          <template v-if="depositsToWithdraw.length">
            <div class="deposit-modal-return__list-title-row">
              <h4 class="deposit-modal-return__list-title">
                {{ $t('WITHDRAWAL_REQUESTS_TITLE') }}
              </h4>
            </div>

            <deposit-modal-return-item
              class="deposit-modal-return__list-item"
              v-for="item of depositsToWithdraw"
              :key="item.id"
              :deposit="item"
              :is-chosen="isChosen(item)"
              @toggle-choose="toggleChoose(item)"
            />

            <div class="deposit-modal-return__list-title-row">
              <h4 class="deposit-modal-return__list-title">
                {{ $t('OTHER_DEPOSITS_TITLE') }}
              </h4>
            </div>
          </template>

          <deposit-modal-return-item
            class="deposit-modal-return__list-item"
            v-for="item of otherDeposits"
            :key="item.id"
            :deposit="item"
            :is-chosen="isChosen(item)"
            @toggle-choose="toggleChoose(item)"
          />
        </div>

        <div class="deposit-modal-return__actions">
          <ui-button
            class="deposit-modal-return__submit-btn"
            :is-disabled="!chosenDeposits.length"
            @click="onReturnClick"
          >
            {{ $tc('RETURN_BTN', chosenCount, {
              count: chosenCount,
              amount: $fc(chosenAmount, currency),
            }) }}
          </ui-button>
        </div>
      </div>
    </ui-modal>
  </ui-portal>
</template>

<script>
import { UiButton, UiModal, UiPortal } from '@shelf.network/ui-kit'
import { Deposit } from 'Models/Deposit'
import { validateArrayOf } from 'Models/modelUtils'
import { formatDateRelative } from 'Utils/dateHelpers'
import DepositModalReturnItem from './DepositModalReturnItem'

export default {
  name: 'deposit-modal-return',

  components: {
    UiButton,
    UiModal,
    UiPortal,
    DepositModalReturnItem,
  },

  props: {
    isShown: {
      type: Boolean,
      default: false,
    },

    deposits: {
      type: Array,
      required: true,
      validator: validateArrayOf(Deposit),
    },
  },

  data () {
    return {
      chosenDeposits: [],
      depositsToWithdraw: [],
      otherDeposits: [],
    }
  },

  computed: {
    chosenAmount () {
      return this.chosenDeposits
        .reduce((acc, cur) => acc + Number(cur.amount), 0)
    },

    chosenCount () {
      return this.chosenDeposits.length
    },

    currency () {
      return (this.chosenDeposits[0] || {}).currency
    },
  },

  watch: {
    isShown: {
      handler (value) {
        if (value) {
          this.chosenDeposits =
            this.depositsToWithdraw.filter(el => el.isReturnable)
        }
      },
      immediate: true,
    },

    deposits: {
      handler () {
        const toWithdraw = []
        const others = []

        for (const deposit of this.deposits) {
          if (deposit.state !== Deposit.statesEnum.requestedWithdrawal) {
            others.push(deposit)
            continue
          }

          toWithdraw.push(deposit)
          if (deposit.isReturnable) this.choose(deposit)
        }

        this.depositsToWithdraw = toWithdraw
        this.otherDeposits = others
      },
      immediate: true,
    }
  },

  methods: {
    formatDateRelative,

    toggleChoose (deposit) {
      if (this.isChosen(deposit)) {
        this.unchoose(deposit)
      } else {
        this.choose(deposit)
      }
    },

    unchoose (deposit) {
      this.chosenDeposits = this.chosenDeposits.filter(el => el !== deposit)
    },

    choose (deposit) {
      this.chosenDeposits.push(deposit)
    },

    isChosen (deposit) {
      return this.chosenDeposits.includes(deposit)
    },

    onReturnClick () {
      this.$emit('submit', this.chosenDeposits.map(el => el.id))
      this.close()
    },

    close () {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/deposit-modal";

.deposit-modal-return {
  &__inner {
    @include respond-above(xs) {
      min-width: 25em;
    }
  }

  &__list {
    display: grid;
    max-height: 13em;
    overflow-y: auto;
    gap: 0.75em;

    @include respond-above(xs) {
      max-height: 25em;
    }

    &-head {
      @extend %row;

      padding-top: 0;
      padding-bottom: 0;

      &-cell {
        color: $color-ui-secondary;
      }
    }

    &-title {
      &-row {
        padding: 0.75em $row-side-padding 0;
      }

      font-size: 0.75em;
      font-weight: bold;
    }
  }

  &__actions {
    margin-top: 2em;
  }

  &__submit-btn {
    width: 100%;
  }
}
</style>

<i18n>
{
  "en": {
    "CHOOSE_TITLE": "Choose deposits",
    "RETURN_BTN": "No deposits chosen | Return {amount} | Return {count} deposits ({amount})",
    "AMOUNT_HEAD_CELL": "AMOUNT",
    "CREATED_AT_HEAD_CELL": "DATE",
    "WITHDRAWAL_REQUESTS_TITLE": "WITHDRAWAL REQUESTS",
    "OTHER_DEPOSITS_TITLE": "OTHER DEPOSITS"
  },
  "ka": {
    "CHOOSE_TITLE": "აირჩიეთ დეპოზიტი",
    "RETURN_BTN": "დეპოზიტი არ არჩეულა | დაბრუნება {amount} | დაბრუნება {count} დეპოზიტები ({amount})",
    "AMOUNT_HEAD_CELL": "ᲗᲐᲜᲮᲐ",
    "CREATED_AT_HEAD_CELL": "ᲗᲐᲠᲘᲦᲘ",
    "WITHDRAWAL_REQUESTS_TITLE": "ᲓᲐᲑᲠᲣᲜᲔᲑᲘᲡ ᲛᲝᲗᲮᲝᲕᲜᲐ",
    "OTHER_DEPOSITS_TITLE": "ᲡᲮᲕᲐ ᲓᲔᲞᲝᲖᲘᲢᲘ"
  },
  "ru": {
    "CHOOSE_TITLE": "Выберите депозиты",
    "RETURN_BTN": "Не выбрано депозитов | Вернуть {amount} | Вернуть {count} депозита ({amount}) | Вернуть {count} депозитов ({amount})",
    "AMOUNT_HEAD_CELL": "СУММА",
    "CREATED_AT_HEAD_CELL": "ДАТА",
    "WITHDRAWAL_REQUESTS_TITLE": "ЗАПРОСЫ НА СНЯТИЕ",
    "OTHER_DEPOSITS_TITLE": "ОСТАЛЬНЫЕ ДЕПОЗИТЫ"
  },
  "uk": {
    "CHOOSE_TITLE": "Оберіть депозити",
    "RETURN_BTN": "Не обрано депозитів | Повернути {amount} | Повернути {count} депозити ({amount}) | Повернути {count} депозитів ({amount})",
    "AMOUNT_HEAD_CELL": "СУМА",
    "CREATED_AT_HEAD_CELL": "ДАТА",
    "WITHDRAWAL_REQUESTS_TITLE": "ЗАПИТИ НА ЗНЯТТЯ",
    "OTHER_DEPOSITS_TITLE": "ІНШІ ДЕПОЗИТИ"
  }
}
</i18n>
