<template>
  <button
    class="deposit-modal-return-item"
    @click="$emit('toggle-choose')"
    :disabled="!deposit.isReturnable"
    :title="itemHint"
  >
    <div class="deposit-modal-return-item__inner">
      <span class="deposit-modal-return-item__cell">
        <ui-check-decor
          class="deposit-modal-return-item__check"
          :is-checked="isChosen"
        />
      </span>

      <span class="deposit-modal-return-item__cell">
        {{ $fc(deposit.amount, deposit.currency) }}
      </span>

      <span class="deposit-modal-return-item__cell">
        {{ formatDateRelative(deposit.createdAt) }}
      </span>
    </div>
  </button>
</template>

<script>
import { UiCheckDecor } from '@shelf.network/ui-kit'
import { Deposit } from 'Models/Deposit'
import { formatDateRelative } from 'Utils/dateHelpers'

export default {
  name: 'deposit-modal-return-item',

  components: {
    UiCheckDecor,
  },

  props: {
    deposit: {
      type: Deposit,
      required: true,
    },

    isChosen: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      chosenDeposits: [],
    }
  },

  computed: {
    itemHint () {
      if (this.deposit.isReturnable) return

      if (this.deposit.isReturned) {
        return this.$t('ALREADY_RETURNED_HINT')
      }

      return this.$t('CANNOT_RETURN_HINT')
    }
  },

  methods: {
    formatDateRelative,
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/deposit-modal";

.deposit-modal-return-item {
  text-align: start;
  background: none;
  border: none;
  border-radius: 1em;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:enabled {
    cursor: pointer;
    background: $color-light-grey;
  }

  &__inner {
    @extend %row;
  }

  &__check {
    line-height: unset;
    pointer-events: none;

    /deep/ .ui-check-decor__frame {
      margin-right: 0;
      display: block;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CANNOT_RETURN_HINT": "The deposit cannot be returned",
    "ALREADY_RETURNED_HINT": "The deposit returned or being returned"
  },
  "ka": {
    "CANNOT_RETURN_HINT": "დეპოზიტი ვერ დაბრუნდა",
    "ALREADY_RETURNED_HINT": "დეპოზიტი დაბრუნებულია"
  },
  "ru": {
    "CANNOT_RETURN_HINT": "Депозит не может быть возвращен",
    "ALREADY_RETURNED_HINT": "Депозит возвращен или возвращается"
  },
  "uk": {
    "CANNOT_RETURN_HINT": "Депозит не може бути повернений",
    "ALREADY_RETURNED_HINT": "Депозит повернено або повертається"
  }
}
</i18n>
