export const depositsActions = {
  LOAD_HISTORY: 'LOAD_HISTORY',
  LOAD_ACTIVE_DEPOSITS: 'LOAD_ACTIVE_DEPOSITS',
  LOAD_MORE: 'LOAD_MORE',
  SET_DEPOSITS_STATES: 'SET_DEPOSITS_STATES',
  CHARGE_DEPOSIT: 'CHARGE_DEPOSIT',
}

export const depositsMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_LIST: 'SET_LIST',
  UPDATE_LIST_ITEM: 'UPDATE_LIST_ITEM',
  UPDATE_LIST_ITEMS: 'UPDATE_LIST_ITEMS',
  PUSH_LIST: 'PUSH_LIST',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_FETCH_NEXT: 'SET_FETCH_NEXT',
  UPDATE_DEPOSIT_BROKER: 'UPDATE_DEPOSIT_BROKER',
}

export const depositsGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  LIST_RAW: 'LIST_RAW',
  LIST_COUNT: 'LIST_COUNT',
  FETCH_NEXT: 'FETCH_NEXT',
}
