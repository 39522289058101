<template>
  <div class="deposit-cell-created-at deposits-cmn__cell">
    <span
      class="deposits-cmn__str deposits-cmn__str_sec"
      :title="date"
    >
      {{ date }}
    </span>
  </div>
</template>

<script>
import { Deposit } from 'Models/Deposit'
import { formatDateTimeRelative } from 'Utils/dateHelpers'
import { DEPOSITS_LEAD_TABS } from 'Constants/depositsUrlParams'

export default {
  name: 'deposit-cell-created-at',

  props: {
    deposit: {
      type: Deposit,
      required: true,
    },
  },

  computed: {
    date () {
      const isLeadTab = DEPOSITS_LEAD_TABS.includes(this.$route.params.tab)
      return isLeadTab
        ? formatDateTimeRelative(this.deposit.createdAt)
        : formatDateTimeRelative(this.deposit.updatedAt)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/deposits.scss";
</style>
